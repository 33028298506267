<template>
  <div class="home">
    <top></top>
  <div class="center">
    <div class="nav-wrap">
     <div class="item" >
       <div class="list">
       <div class="img">
           <viewer>
          <img :src="this.information.image"  alt="">
          </viewer>
       </div>
       <div class="message">
         <div class="name">
           姓名：{{this.information.name}}
         </div>
         <p>{{this.information.describe}}</p>
         <p class="curis">
           <span v-for="(item,i) in label" :key="i">{{item}}</span>
         
         </p>
       </div>
       <div class="questions">
         <button type="button" class="button"   @click="dialogTableVisible=true">发起沟通</button>
       </div>
    <!-- 问一问弹框 -->
    <div class="tan">
     <el-dialog :visible.sync="dialogTableVisible" :before-close="tanconfirm"  :close-on-click-modal="false">
                <div class="confirm">
                  <el-form class="confirmform" label-width="80px">
                    <el-form-item label="标题">
                      <el-input v-model='formdata.title' placeholder="请输入您想问的问题标题..."></el-input>
                    </el-form-item>
                    <el-form-item label="描述">
                      <el-input   type="textarea" class="desc" v-model='formdata.describe' placeholder="请较为详细的记录您提问的问题，等待专家回复..."></el-input>
                    </el-form-item>
                    <div class="describe">补充照片(最多三张)</div>
                    <el-form-item>
                      
                      <el-upload
			 :action="baseApi+ '/center/file/upload'"
			  list-type="picture-card"
			  :on-success="handlePictureCardPreview"
        :before-upload="beforeAvatarUpload"
         :headers="httpheader"
			  :on-remove="handleRemove">
			  <i class="el-icon-plus"></i>
			</el-upload>
                   <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  type="danger"
                  class="fabu"
                  @click="reselve(id)"
                  >发布</el-button
                >
              </el-dialog>

</div>

       
     </div>
     </div>

     <div class="comment ">
         <div class="tile">
             全部评论
         </div>
         <!-- v-for="(item,index) in expertpronlem" :key="index" -->
         <div class="person" v-for="(item,index) in expertpronlem" :key="index">
             <div class="top">
               <div class="img">
                 <img  v-if="item.userimage!=null&&item.userimage!=''" :src="item.userimage">
                 <img v-else src="../../assets/home/nullimage.png" >
                </div>
                <div  class="name">
                  <p>{{item.username}}</p>
                 <p>{{item.companyname}}</p>
                 </div> 
                 <div class="anster" v-if="item.reply">已回答</div>
                  <div class="anster" v-else>未回答</div>
             </div>
             <div class="query">
                {{item.describe}}
             </div>
             <div class="reply" v-if="item.reply">
                 <i class="before"></i>
                <div class="left">
                  <!-- <img :src="this.information.image" alt=""> -->
                    <img :src="information.image" alt=""> 
                    </div> 
                    <div class="right">
                        <span>{{information.name}}</span>
                        <span>专家回复</span>
                        <p>{{item.reply}}</p>
                    </div>
             </div>

         </div>
           <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-size="queryInfo.pageSize"
          layout="total,  prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination> -->
    <Pagination class="el-pagination " :num="total" :limit="queryInfo.pageSize" :totalPageCount="totalPageCount"></Pagination>
     </div>
    </div>
  </div>
 
    <Foo class="footerrr"></Foo>
     
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import expertApi from "../../api/expertApi"
import Pagination from "@/components/prePage/pagination.vue";
import { Message } from "element-ui";
import imageZip from "../../utils/imageZip"
import basecfg from "../../utils/applocationCfg"
export default {
  data(){
    return{
       httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
       baseApi:basecfg.getBaseApi(),
    dialogTableVisible:false,
      dialogVisible: false,
      urlimg:"",
       dialogImageUrl: "",
     formdata:{
        title:'',
        describe:'',
        fileList:[],
      
      },
       id: this.$route.query.id,
    total:0,
       //咨询类表
       expertpronlem:[],
       //专家信息
       information:"",
       label:"",
        queryInfo: {
        pageNum: 1,
        pageSize: 5,
      },
 
    }
  },
 mounted(){
   var movieId = this.$route.params 
   
 },
  components: {
    // HelloWorld
    Top,
    Foo,
    Pagination
 
  },
  created(){
    this.expertpronlemlist()
    this.getInformation()
  },
  methods:{
        // 限制大小
         beforeAvatarUpload(file) {
       const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
      
    },
    //获取专家信息
    getInformation(){
      expertApi.getInformation({id:this.id},(res)=>{
        //成功
        this.information = res.data.result
      
      
       this.label =JSON.parse(this.information.label)
        
      })
    },
    //获取咨询类表
      expertpronlemlist(){
expertApi.consultList({id:this.id,pageNum:this.queryInfo.pageNum,pageSize:this.queryInfo.pageSize},(res)=>{
  this.expertpronlem = res.data.result.data

  this.total = res.data.result.totalCount
  this.totalPageCount = res.data.result.totalPageCount
})
      },
        //  监听pagesize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.expertpronlemlist();
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.expertpronlemlist();
    },
     handleRemove(file, fileList) {
       let fileListnew = []
      fileList.forEach(element => {
        let eleme =   element.response.result
          fileListnew.push(eleme)
      });
       this.formdata.fileList = fileListnew
    },
    handlePictureCardPreview(file) {
      this.urlimg = file.result
      this.dialogImageUrl = file.url;
      this.formdata.fileList.push(file.result)
    },
     reselve(expertid){
 
      if(this.formdata.describe&&this.formdata.title){
                 expertApi.questions({title:this.formdata.title,describe:this.formdata.describe,files:this.formdata.fileList,expertid:expertid},(res)=>{
        if(res.data.code==200){
  Message.success("发布成功");
         setTimeout(()=>{
            this.expertpronlemlist()
         },1000)
          this.formdata.describe=""
    
    this.formdata.fileList=null
    this.formdata.title=""
    this.dialogTableVisible=false
        }
        else{
          Message.error(res.data.message);
        }
       
   
     })
      }
      else{
          Message.error("请填写完整");
      }
 
   
    },
    // 关闭弹窗
    tanconfirm(){
        // this.dialogImageUrl=''
    this.dialogTableVisible=false
    
    this.formdata.describe=""
   
    this.formdata.fileList=""
    this.formdata.title=""
  
    }
     
  },
  mounted(){
     
   

  }
}
</script>
<style scoped>
 .center{
   width: 100%;
    
 height: 670px;
   /* background-color: rgb(245, 245, 245); */
 }
.home{
  background-color: rgb(245, 245, 245);
  /* border: 1px solid red; */
   min-width: 1100px;
}
.el-pagination{
  /* border: 1px solid red; */
  width: 100%;
  /* margin-left: 40%; */
margin: 30px 30%;
 
    
}
.nav-wrap{
  /* border: 1px solid red; */
  height: 100%;
  width: 70%;
  margin: auto;
 margin-top: 20px;
  min-width: 900px;
}
.item{
  /* border: 1px solid red; */
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
 
}
.list{
   display: flex;
  width: 100%;
  height: 120px;
  justify-content: space-between;
  padding: 8px;
}

.img{
/* border: 1px solid rgb(98, 0, 255); */
width:10%;

}


.img img{
  width: 100px;
  height:100px;
  /* border: 1px solid red; */
}
.message{
  width: 76%;
/* border: 1px solid rgb(60, 0, 255); */
padding-left: 20px;
padding-top: 16px;
}
.message .name{
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
}
.message p:nth-child(2){
    /* border: 1px solid red; */
    height: 53%;
    overflow: hidden;
    text-overflow:ellipsis; 
white-space:wrap; 
}
.message p{
  font-size: 14px;
  /* border: 1px solid red; */
  margin-top: 5px;
/* font-size: 20px; */
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.87);
/* line-height: 28px; */
}
.questions{
  width: 10%;
  /* border: 1px solid rgb(60, 0, 255); */
  
}
.curis span{
 margin-right: 10px;
 font-weight: 600;
 color:rgb(233, 79, 85) ;
}
.curis{
  /* border: 1px solid red; */
    margin-top: -15px;


}

.questions .button{
  background-color: rgb(233, 79, 85);
  border: 0;
  width: 70px;
  height: 30px;
  line-height: 30px;
  color: white;
  border-radius: 5px;
  margin-top: 40px;
  
}
.comment{
    width:100%;
    /* height: 620px; */
    background-color: white;
    margin-top: 10px;
    /* border: 1px solid red; */
}
.comment img{
    width: 50px;
    height: 50px;
    
}
.tile{
    width: 72px;
    height: 22px;
    padding: 10px;
    /* border: 1px solid red; */
}
.person{
  border-bottom: 1px solid rgb(231,231,231) ;
padding: 10px;
 /* border: 1px solid red; */
}
.person .top{
   /* border: 1px solid red; */
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   height: 50px;
   width: 100%;
    /* border: 1px solid red; */
}
.person .top .img{
  width: 5%;
}

.person .top .name{
  width: 90%;
  height: 50px;
 /* border: 1px solid red; */
 margin-left: 1%;
}
.person .top .name p{
  margin: 0;
  padding: 0;
   /* border: 1px solid red; */
   margin-top: 2px;
   
 
}

.person .top .name p:nth-child(1){
  font-weight: 600;
  font-size: 14px;
 
}
.person .top .name p:nth-child(2){
/* border: 1px solid red; */
  /* width: 170px; */
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
padding-top: 5px;
}
.anster{
  color: red;
  width: 6%;
}

.query{
   font-size: 14px;
  height: 20px;
  margin-left: 6%;
  line-height: 1.5;

  font-family: PingFangSC-Regular, PingFang SC;
}

.reply{
/* border: 1px solid red; */
background-color: #EFEFEF;
margin-top: 10px;
margin-left: 50px;
display: flex;
padding: 5px;
z-index: 100;
}
.reply .right{
  /* border: 1px solid red; */
 width: 70%;
 height: 50px;
 /* padding: 0; */
 margin-left: 10px;

position: relative;
}
.reply .right span:nth-child(1){
  width: 34px;
height: 22px;
font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 600;
color: rgba(0, 0, 0, 0.87);
line-height: 22px;
margin-right: 3px;

}
.reply .right span:nth-child(2){
  color: white;
  background-color: #E94F55;
  width: 36px;
border-radius: 2px;
  height: 13px;
  margin-left: 2px;
  font-size: 9px;
  padding: 2px;
  
}
.reply .right p{
  margin-top: 5px;
  color:rgb(168, 167, 167);
  font-size: 14px;
}
.reply .before{
  /* border: 1px solid red; */
  width: 6px;
  height: 6px;
  position: relative;
  top: -8px;
  left: 20px;
  -webkit-transform: rotate(35deg);
  background-color: rgb(239, 239, 239);
  z-index: 1;
   
}
.confirm {
  width: 600px;
  border-radius: 20px;
}

.tan ::v-deep .el-dialog {
  width: 800px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.tan .el-icon-plus{
  /* border: 1px solid red; */
  /* margin-top: -50px !important; */
  position: relative;
  top: -20px;
}
.confirmform {
  width: 100%;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
.desc > ::v-deep .el-textarea__inner {
  height: 300px;
  /* border: 1px solid red; */
  width: 100%;
}
.describe {
  margin-left: 40px;
}
.fabu {
  width: 300px;
  margin-left: 200px;
}

</style>
